$aui-pager-color: rgba(#000, 0.5) !default;
$aui-pager-color-active: rgba(#000, 1) !default;

.aui-pager {
  list-style: none;
  padding-left: 0;
  font-weight: $aui-font-weight-normal;
  color: $aui-pager-color;

  &__item {
    display: inline-block;
  }

  &__current {
    color: $aui-pager-color-active;
  }

  &__next,
  &__prev {
    transition: color .15s linear;
  }

  &__prev {
    margin-right: 1em;
  }

  &__next {
    margin-left: 1em;
  }

  &__next-icon,
  &__prev-icon {
    display: inline-block;
    width: .5em;
    height: .8125em;
    text-indent: 100%;
    overflow: hidden;
    transform: translateY(em(1));
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    svg {
      @include icon;
    }
  }

  &__prev-icon {
    transform: translateY(em(1)) rotate(180deg);
  }

  &__next:hover:not(.is-disabled),
  &__prev:hover:not(.is-disabled) {
    color: $aui-pager-color-active;
  }

  &__next,
  &__prev {
    &.is-disabled {
      cursor: not-allowed;
      opacity: .30;
      pointer-events: none;
    }
  }
}
