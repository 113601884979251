.audiicon {
  display: inline-block;
  color: inherit;
  stroke-width: 1.1px;
}
.audiicon-small {
  width: 24px;
  height: 24px;
}
.audiicon-large {
  width: 48px;
  height: 48px;
}
