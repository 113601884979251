$aui-radio-light-color-text: $aui-color-gray95 !default;
$aui-radio-light-color-text-hover: $aui-color-white !default;
$aui-radio-light-color-border: rgba(#fff, 0.5) !default;
$aui-radio-light-color-border-hover: rgba(#fff, 1) !default;
$aui-radio-light-color-tick: rgba(#fff, 0.2) !default;
$aui-radio-light-color-tick-active: rgba(#fff, 0.8) !default;

.aui-radio {
  /**
   * Theme: light
   */
  &.aui-theme-light {

    color: $aui-radio-light-color-text;

    .aui-radio__box {
      border-color: $aui-radio-light-color-border;
    }

    .aui-radio__tick {
      background: $aui-radio-light-color-tick;
    }

    // Hover state
    &:hover:not(.is-disabled) {
      color: $aui-radio-light-color-text-hover;

      .aui-radio__box {
        border-color: $aui-radio-light-color-border-hover;
      }
    }

    // Focus state
    .aui-radio__input:focus ~ .aui-radio__box {
      @include shadow-focus-light;
    }

    // Checked state
    &.is-checked .aui-radio__tick {
      background: $aui-radio-light-color-tick-active;
    }
  }
}
