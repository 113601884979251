$aui-switch-light-color-text: $aui-color-gray95 !default;
$aui-switch-light-color-text-hover: $aui-color-white !default;
$aui-switch-light-color-text-inactive: rgba(#fff, 0.5) !default;
$aui-switch-light-color-track-border: rgba(#fff, 0.5) !default;
$aui-switch-light-color-track-border-hover: rgba(#fff, 1) !default;
$aui-switch-light-color-thumb: rgba(#fff, 0.2) !default;
$aui-switch-light-color-thumb-active: rgba(#fff, 0.8) !default;

.aui-switch {
  /**
   * Theme: light
   */
  &.aui-theme-light {

    .aui-switch__text {
      color: $aui-switch-light-color-text;
    }

    .aui-switch__track {
      border-color: $aui-switch-light-color-track-border;
    }

    .aui-switch__thumb {
      background: $aui-switch-light-color-thumb;
    }

    &.aui-switch--equal {
      .aui-switch__text--right {
        color: $aui-switch-light-color-text-inactive;
      }

      .aui-switch__thumb {
        background: $aui-switch-light-color-thumb-active;
      }
    }

    // Hover state
    &:hover:not(.is-disabled) {
      .aui-switch__text {
        color: $aui-switch-light-color-text-hover;
      }

      .aui-switch__track {
        border-color: $aui-switch-light-color-track-border-hover;
      }
    }

    &.aui-switch--equal:hover:not(.is-disabled) .aui-switch__text--right {
      color: $aui-switch-light-color-text-inactive;
    }

    &.aui-switch--equal:hover:not(.is-disabled) .aui-switch__input:checked ~ .aui-switch__text--left {
      color: $aui-switch-light-color-text-inactive;
    }

    &.aui-switch--equal:hover:not(.is-disabled) .aui-switch__input:checked ~ .aui-switch__text--right {
      color: $aui-switch-light-color-text-hover;
    }

    // Focus state
    .aui-switch__input:focus ~ .aui-switch__track {
      @include shadow-focus-light;
    }

    // Checked state
    .aui-switch__input:checked ~ .aui-switch__track .aui-switch__thumb {
      background: $aui-switch-light-color-thumb-active;
    }

    &.aui-switch--equal .aui-switch__input:checked ~ .aui-switch__text--left {
      color: $aui-switch-light-color-text-inactive;
    }

    &.aui-switch--equal .aui-switch__input:checked ~ .aui-switch__text--right {
      color: $aui-switch-light-color-text;
    }
  }
}
