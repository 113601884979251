$aui-select-light-color: $aui-color-gray95 !default;
$aui-select-light-color-secondary: rgba(#fff, 0.5) !default;
$aui-select-light-border: rgba(#fff, 0.5) !default;
$aui-select-light-border-hover: rgba(#fff, 0.8) !default;
$aui-select-light-border-focus: rgba(#fff, 0.8) !default;
$aui-select-light-border-dirty: rgba(#fff, 0.8) !default;
$aui-select-light-color-arrow: $aui-field-placeholder-color-light !default;
$aui-select-light-color-arrow-dirty: $aui-select-light-color !default;
$aui-select-light-panel-background: $aui-color-black !default;

.aui-select {
  /**
   * Theme: light
   */
  &.aui-theme-light {

    .aui-select__input {
      color: $aui-field-placeholder-color-light;
      border-color: $aui-select-light-border;

      // HACK IE: Use placeholder color, if default value is selected.
      &::-ms-value {
        color: $aui-field-placeholder-color-light;
      }
    }

    .aui-select__label {
      color: $aui-field-placeholder-color-light;
    }

    // Arrow icon
    &::after {
      background: url('data:image/svg+xml,#{aui-svg-dropdown-arrow($aui-select-light-color-arrow)}') center center no-repeat;
    }

    .aui-select__focus-line {
      background: $aui-select-light-border-hover;
    }

    .aui-select__description,
    .aui-select__error {
      color: $aui-select-light-color-secondary;
    }

    .aui-select__error {
      color: $aui-field-error-color;
    }

    /**
    * States
    */

    // Hover state
    &:not(.is-disabled):not(.is-invalid) {
      .aui-select__input:hover {
        border-color: $aui-select-light-border-hover;
      }
    }

    // Dirty state
    &.is-dirty {
      // Arrow icon
      &::after {
        background-image: url('data:image/svg+xml,#{aui-svg-dropdown-arrow($aui-select-light-color-arrow-dirty)}');
      }

      .aui-select__input {
        border-color: $aui-select-light-border-dirty;
        color: $aui-select-light-color;

        // Style color on IE10+ when selected
        &::-ms-value {
          color: $aui-select-light-color;
        }
      }
    }

    // Invalid state
    &.is-invalid {
      &,
      .aui-select__label,
      &:not(.is-dirty) .aui-select__input {
        color: $aui-field-error-color;
      }

      // Arrow icon
      &::after {
        background-image: url('data:image/svg+xml,#{aui-svg-dropdown-arrow($aui-field-error-color)}');
      }

      .aui-select__input {
        border-color: $aui-field-error-color;
      }

      .aui-select__focus-line {
        background: $aui-field-error-color;
      }
    }
  }
}
