$aui-indicator-color: rgba(#000, 0.2) !default;
$aui-indicator-color-hover: rgba(#000, 0.5) !default;
$aui-indicator-color-active: rgba(#000, 0.8) !default;

$aui-indicator-dot-width: unit(5) !default;
$aui-indicator-height: unit(2) !default;

.aui-indicator {
  display: inline-block;
  position: relative;
  font-family: serif;
  height: $aui-indicator-height;
  line-height: $aui-indicator-height;

  &__items {
    padding-left: 0;
    list-style: none;
    margin: 0;
    @include clearfix;
  }

  &__item {
    float: left;
    margin-left: unit(1);

    &:first-child {
      margin-left: 0;
    }
  }

  &__action {
    display: block;
    width: $aui-indicator-dot-width;
    height: $aui-indicator-height;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    text-decoration: none;
    outline: 0 !important;
    transition: background 0.15s linear;
  }

  &__action,
  &__action:visited {
    background: $aui-indicator-color;
  }

  &__action:hover {
    background: $aui-indicator-color-hover;
  }

  &__indicator {
    pointer-events: none;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: $aui-indicator-dot-width;
    height: 100%;
    background: $aui-indicator-color-active;
    z-index: 1;
    transition: left 0.3s $aui-easing;
  }

  &--shadow &__action {
    box-shadow: 0 0 8px rgba(#000, 0.5);
  }
}
