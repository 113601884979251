@import "variables";
@import "../audi-ui-components/styles/main";

// bootstrap
$grid-breakpoints: map-merge((zero: 0), $aui-breakpoint);
$border-radius: 0;
$zindex-tooltip: 2000;
@import 'bootstrap';

body {
  background: $aui-color-gray95;
}
.app__inner {
  background: $aui-color-white;
}
.app__container {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}
.banner {
  width: 100%;
}
.form-wrapper {
  max-width: 700px;
  margin: 0 auto;
}
header {
  .logo {
    display: block;
    height: 30px;
    svg {
      height: 30px;
    }
  }
}
footer {
  background: $aui-color-black;
  color: $aui-color-white;
  nav {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
  }
  hr {
    border: solid rgba($aui-color-white, .5);
    border-width: 1px 0 0;
  }
}
iframe {
  width: 100%;
  border: none;
  height: 1500px;
}
